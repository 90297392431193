import { type ApolloQueryResult } from '@apollo/client'
import { Filters } from '@features/lists/components/Filters'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { InterviewLateFilter } from './InterviewLateFilter'
import { TrackOfferFilter } from '@features/lists/components/Filters/TrackOfferFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { AssessorsFilter } from '@features/lists/components/Filters/AssessorsFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import LocationFilter from '@domains/location/LocationsFilter'
import BusinessUnitFilter from '@domains/businessUnit/BusinessUnitFilter'
import { PromotionFilter } from '@domains/promotion/PromotionFilter'
import { CustomFieldsFilters } from '@features/lists/components/Filters/customFields/CustomFieldsFilters'
import { InterviewTypeIdFilter } from '@features/lists/components/Filters/InterviewTypeIdFilter'
import { InterviewUserIntegrationStatusFilter } from './filter/InterviewUserIntegrationStatusFilter'
import { filterOperation } from '@ui/filter/FilterForm'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useRole } from '@shared/hooks/useRole'
import { Mode } from '@application/dashboard/ModeToggle'
import { LowScoreFilter } from './LowScoreFilter'
import { useMeQuery } from '@gqlTypes'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	mode?: Mode
}

export const InterviewsFilters = ({ refetch, mode = Mode.List }: Props): ReactElement => {
	const isList = mode === Mode.List
	const { t } = useCustomTranslation()
	const { isAssessor } = useRole()
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const hasInterviewSignature = data?.me?.organization?.settings?.interview?.hasInterviewSignature ?? true

	return (
		<Filters refetch={refetch}>
			{isList ? (
				<FiltersContent>
					<Fullname firstNameKey="userFirstnameLowercase" lastNameKey="userLastnameLowercase" />
					<TextFilter keyName="userRef.externalId" label={t('lists:filter.externalId')} />
				</FiltersContent>
			) : null}
			<FiltersContent>
				{isList ? <InterviewLateFilter keyName="hasOnlyLateInterview" /> : null}
				<LowScoreFilter />
				<TrackOfferFilter keyName="orgaOfferId" labelAll={t('lists:filter.offer.allLabel')} hasNoneValue={isList} />
				<TrackPositionFilter keyName="orgaPositionId" hasNoneValue={isList} />
				{!isAssessor && <AssessorsFilter keyName="juryId" hasNoneValue={isList} />}
				{isList ? (
					<>
						<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
						<CheckboxInput keyName="stepsState.interview" translationKey="lists:filter.realised" />
					</>
				) : null}
				{hasInterviewSignature ? (
					<CheckboxInput keyName="stepsState.interviewSignature" translationKey="lists:filter.signed" />
				) : null}
				<LocationFilter isBasedOnOrgaType hasNoneValue={isList} />
				<BusinessUnitFilter />
				<PromotionFilter hasNoneValue={isList} />
				<CustomFieldsFilters hasNoneValue={isList} />
				{isList ? <InterviewTypeIdFilter /> : null}
				<InterviewUserIntegrationStatusFilter hasNoneValue={isList} />
				<TextFilter
					type="date"
					keyName="targetDate"
					operation={filterOperation.GREATER_THAN_EQUAL}
					label={t('lists:filter.minTargetDate')}
					InputLabelProps={{ shrink: true }}
				/>
				<TextFilter
					type="date"
					keyName="targetDate"
					operation={filterOperation.LOWER_THAN_EQUAL}
					label={t('lists:filter.maxTargetDate')}
					InputLabelProps={{ shrink: true }}
				/>
			</FiltersContent>
		</Filters>
	)
}
